import Api from "@/services";

const createContent = async (object) => {
  const response = await Api.post("v1/contents", object);
  return response;
};

const getContents = async (query) => {
  try {
    const response = await Api.query("v1/contents", query);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getContentById = async (id) => {
  try {
    const response = await Api.get("v1/contents", id);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const updateContent = async (object, id) => {
  try {
    const response = await Api.put("v1/contents", id, object);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const deleteContent = async (id) => {
  try {
    const response = await Api.delete("v1/contents", id);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getContentsByWeek = async () => {
  try {
    const response = await Api.query("v1/contents/contents-by-week");
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export default {
  createContent,
  getContents,
  getContentById,
  updateContent,
  deleteContent,
  getContentsByWeek,
};
